import {productVariantFieldsFragment} from './fragments';

/**
 * Query to get the product and variants for the product store.
 */
export const getProductByIdQuery = /* GraphQL */ `
  query getProductById(
    $id: ID!
    $product_metafields: [HasMetafieldsIdentifier!]!
    $variant_metafields: [HasMetafieldsIdentifier!]!
  ) {
    product(id: $id) {
      description
      id
      featuredImage {
        url
        altText
      }
      handle
      metafields(identifiers: $product_metafields) {
        key
        type
        value
      }
      title
      options {
        name
        values
      }
      requiresSellingPlan
      variants(first: 100) {
        edges {
          node {
            ...ProductVariantFields
          }
        }
      }
    }
  }

  ${productVariantFieldsFragment}
`;
