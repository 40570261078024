import type {GetProductInput, Product, ProductVariant} from '@/product/types';

import type {Product as ProductStorefront} from '@shopify/hydrogen-react/storefront-api-types';
import {logError} from '@/utilities/log';
import {client} from '@/shopify-storefront/client';
import {createProduct} from '@/product/product';
import {createShopifyGID} from '@/utilities/create-gid';
import {getProductByIdQuery} from '@/product/queries';
import {signal} from '@preact/signals';
import {withDeduplication} from '@/utilities/with-deduplication';

/**
 * The metafields to fetch for each product variant.
 * Passed as a variable in the Storefront GraphQL API query.
 */
export const variantMetafieldIdentifiers = [
  {
    key: 'number_of_loads',
    namespace: 'custom',
  },
  {
    key: 'pack_size',
    namespace: 'custom',
  },
];

const productMetafieldIdentifiers = [
  {
    namespace: 'bv-custom',
    key: 'number_of_reviews',
  },
  {
    namespace: 'bv-custom',
    key: 'average_rating',
  },
];

const products = signal<Map<string, Product | null>>(new Map());

/**
 * Returns a product by its ID. If the product is not yet loaded, it will be
 * loaded asynchronously.
 * Can be subscribed to using preact signals to be notified when the product
 * is loaded.
 * @param id - may be a string or number. If a number is provided, it will be
 * converted to a string and prefixed with `gid://shopify/Product/`.
 * @returns the product if it is already loaded, or null if it is not yet
 * @example Usage in vanilla TS
 * ```ts
 * import {getProduct} from '@/product/product-store';
 * import {effect} from '@preact/signals';
 *
 * // Subscribe to product in vanilla TS
 * effect(() => {
 *  const product = getProduct('gid://shopify/Product/123');
 * if (product) {
 *   // do something with the product
 * });
 * ```
 *
 * @example Usage in a Preact component
 * ```tsx
 * import {getProduct} from '@/product/product-store';
 *
 * // Subscribe to product in JSX
 * export default function MyComponent({productId}: {productId: number}) {
 *   // the component will re-render when the product is loaded
 *   const product = getProduct(productId);
 *   // we can display a placeholder while the product is null
 *   return product ? <div>{product.title}</div> : <div>Loading...</div>;
 * }
 * ```
 */
export function getProduct(id: string | number): Product | null {
  const gid = typeof id === 'string' ? id : createShopifyGID(id, 'Product');
  const product = products.value.get(gid);

  if (!product) {
    const handleError = (error: Error) => {
      logError(error, {
        productId: gid,
      });
    };
    withDeduplication(gid, () => loadProduct(gid)).catch(handleError);
    return null;
  }
  return product;
}

/**
 * Returns a product variant by its ID. If the product is not yet loaded, it
 * will be loaded asynchronously.
 * Can be subscribed to using preact signals to be notified when the product
 * is loaded.
 * @param productId - may be a string or number. If a number is provided, it
 * will be converted to a string and prefixed with `gid://shopify/Product/`.
 * @param variantId - may be a string or number. If a number is provided, it
 * will be converted to a string and prefixed with `gid://shopify/ProductVariant/`.
 * @returns the product variant if it is already loaded, or null if it is not yet
 * @example Usage in vanilla TS
 * ```ts
 * import {getVariant} from '@/product/product-store';
 * import {effect} from '@preact/signals';
 *
 * // Subscribe to variant in vanilla TS
 * effect(() => {
 * const variant = getVariant('gid://shopify/Product/123', 'gid://shopify/ProductVariant/456');
 * if (variant) {
 *  // do something with the variant
 * });
 * ```
 *
 * @example Usage in a Preact component
 * ```tsx
 * import {getVariant} from '@/product/product-store';
 *
 * // Subscribe to variant in JSX
 * export default function MyComponent({productId, variantId}: {productId: number, variantId: number}) {
 *  // the component will re-render when the variant is loaded
 * const variant = getVariant(productId, variantId);
 * // we can display a placeholder while the variant is null
 * return variant ? <div>{variant.title}</div> : <div>Loading...</div>;
 * }
 * ```
 */
export function getVariant(
  productId: string | number,
  variantId: string | number
): ProductVariant | null {
  const product = getProduct(productId);
  if (!product) return null;
  const variantGid =
    typeof variantId === 'string'
      ? variantId
      : createShopifyGID(variantId, 'ProductVariant');
  const variant = product.variants.find((v) => v.id === variantGid);
  return variant ?? null;
}

/**
 * Gets a product by its ID and adds it to the products map.
 */
export async function loadProduct(id: string): Promise<void> {
  const input = {
    id,
    product_metafields: productMetafieldIdentifiers,
    variant_metafields: variantMetafieldIdentifiers,
  };

  const product = await getProductHandler(input);

  if (!product) return;

  products.value = new Map([...products.value, [id, product]]);
}

/**
 * Gets a product by its ID.
 */
export async function getProductHandler(
  input: GetProductInput
): Promise<Product | null> {
  const {product} = await client.query<{product: ProductStorefront}>(
    getProductByIdQuery,
    input
  );
  return createProduct(product);
}
