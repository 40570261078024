import type {
  Image,
  PriceAdjustment,
  Product,
  ProductMetafields,
  ProductVariant,
  ProductVariantMetafields,
  SellingPlanAllocation,
} from '@/product/types';
import type {
  Image as ImageStorefront,
  Product as ProductStorefront,
  ProductVariant as ProductVariantStorefront,
  SellingPlanAllocation as SellingPlanAllocationStorefront,
  SellingPlanPercentagePriceAdjustment,
  SellingPlanPriceAdjustment,
} from '@shopify/hydrogen-react/storefront-api-types';

import {createMetafields} from '@/shopify-storefront/metafields';
import {flattenConnection} from '@shopify/hydrogen-react';

/**
 * Converts the Storefront API product object into a product object that is
 * easier to work with.
 */
export function createProduct(product: ProductStorefront): Product {
  const variants = flattenConnection(product.variants).map(createVariant);

  const featuredImage = product.featuredImage
    ? createImage(product.featuredImage)
    : null;

  const metafields = createMetafields<ProductMetafields>(product.metafields);

  return {
    ...product,
    ...metafields,
    featuredImage,
    variants,
  };
}

/**
 * Converts the Storefront API image object into an image object that is
 * easier to work with.
 */
function createImage(image: Pick<ImageStorefront, 'url' | 'altText'>): Image {
  return {
    url: image.url,
    alt: image.altText ?? '',
  };
}

/**
 * Converts the Storefront API variant object into a variant object that is
 * easier to work with.
 */
export function createVariant(variant: ProductVariantStorefront): ProductVariant {
  const {image, metafields, price, sellingPlanAllocations, ...rest} = variant;

  const plans = flattenConnection(sellingPlanAllocations).map(
    createSellingPlanAllocation
  );
  const fields = createMetafields<ProductVariantMetafields>(metafields);

  return {
    image: image ? createImage(image) : null,
    price: parseFloat(price.amount),
    sellingPlanAllocations: plans,
    ...fields,
    ...rest,
  };
}

/**
 * Converts the Storefront API selling plan allocation object into a selling
 * plan allocation object that is easier to work with.
 */
function createSellingPlanAllocation(
  allocation: SellingPlanAllocationStorefront
): SellingPlanAllocation {
  const {price, compareAtPrice} = allocation.priceAdjustments[0];
  const {priceAdjustments, ...rest} = allocation.sellingPlan;
  return {
    price: parseFloat(price.amount),
    compareAtPrice: parseFloat(compareAtPrice.amount),
    sellingPlan: {
      priceAdjustments: priceAdjustments.map(createPriceAdjustment),
      ...rest,
    },
  };
}

/**
 * Converts the Storefront API selling plan price adjustment object into a
 * selling plan price adjustment object that is easier to work with.
 */
function createPriceAdjustment({
  adjustmentValue,
}: SellingPlanPriceAdjustment): PriceAdjustment {
  const percentage =
    (adjustmentValue as SellingPlanPercentagePriceAdjustment)
      .adjustmentPercentage ?? 0;
  return {adjustmentValue: {adjustmentPercentage: percentage}};
}
