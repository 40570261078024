import {ParsedMetafield} from '@/shopify-storefront/types';
import {parseMetafield} from '@shopify/hydrogen-react';
import {HasMetafields} from '@shopify/hydrogen-react/storefront-api-types';

/**
 * Converts a list of Storefront API metafields into an object where the keys
 * are the metafield keys and the values are the parsed metafield values.
 * This allows us to treat metafields the same as any other field.
 */
export function createMetafields<MetafieldsGeneric>(
  metafields: HasMetafields['metafields']
): MetafieldsGeneric {
  const metafieldsObject = metafields
    .filter((field): field is NonNullable<typeof field> => field !== null)
    .reduce((acc, field) => {
      return {
        ...acc,
        [field.key]: parseMetafield<ParsedMetafield>(field)
          .parsedValue as MetafieldsGeneric[keyof MetafieldsGeneric],
      };
    }, {} as MetafieldsGeneric);
  return metafieldsObject;
}
