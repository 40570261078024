/**
 * GraphQL fragment defining the fields to be fetched for a product variant.
 * This allows us to get the same data type in multiple queries.
 *
 * @param variant_metafields - The metafield identifiers to fetch for the variant
 */
export const productVariantFieldsFragment = /* GraphQL */ `
  fragment ProductVariantFields on ProductVariant {
    id
    image {
      url
      altText
    }
    price {
      amount
    }
    metafields(identifiers: $variant_metafields) {
      key
      type
      value
    }
    selectedOptions {
      name
      value
    }
    sellingPlanAllocations(first: 100) {
      edges {
        node {
          priceAdjustments {
            price {
              amount
            }
            compareAtPrice {
              amount
            }
          }
          sellingPlan {
            id
            name
            priceAdjustments {
              adjustmentValue {
                ... on SellingPlanPercentagePriceAdjustment {
                  adjustmentPercentage
                }
              }
            }
            recurringDeliveries
          }
        }
      }
    }
  }
`;
